import React from "react"

const defaultState = {
  dark: false,
  toggleDark: () => {},
  wizardData: {
    time: "",
    company: "",
    age: "",
    own: "",
    engineer: "",
  },
  handleWizardChange: () => {},
  projectRatings: {},
  handleProjectRatingsChange: () => {},
  resetProjectRatings: () => {},
}

const UserContext = React.createContext(defaultState)

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.

class UserProvider extends React.Component {
  state = {
    dark: false,
    wizardData: {
      time: "",
      company: "",
      age: "",
      own: "",
      job: "",
    },
    projectRatings: {},
  }

  toggleDark = () => {
    let dark = !this.state.dark
    localStorage.setItem("dark", JSON.stringify(dark))
    this.setState({ dark })
  }

  // Handle field state change (when any input field is updated)
  handleWizardChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(prevState => ({
      wizardData: {
        ...prevState.wizardData,
        [name]: value,
      },
    }))
  }

  // Handle project update given a list of ratings
  handleProjectRatingsChange = projects => {
    if (projects) {
      projects.map(project => {
        if (!(project.project.id in this.state.projectRatings)) {
          const newRating = project.rating
          const projectID = project.project.id
          this.setState(prevState => ({
            projectRatings: {
              ...prevState.projectRatings,
              [projectID]: Number(newRating),
            },
          }))
        } else if (project.project.id in this.state.projectRatings) {
          const newRating =
            this.state.projectRatings[project.project.id] +
            Number(project.rating)
          const projectID = project.project.id
          this.setState(prevState => ({
            projectRatings: {
              ...prevState.projectRatings,
              [projectID]: Number(newRating),
            },
          }))
        }
      })
    }
  }

  // Resets project ratings to null object {}
  resetProjectRatings = () => {
    this.setState({ projectRatings: {} })
  }

  // THIS CAN SYNC CONTEXT WITH LOCAL STORAGE
  componentDidUpdate() {
    localStorage.setItem("wizardData", JSON.stringify(this.state.wizardData))
    localStorage.setItem(
      "projectRatings",
      JSON.stringify(this.state.projectRatings)
    )
  }

  componentDidMount() {
    // Getting values from localStorage!
    const lsDark = JSON.parse(localStorage.getItem("dark"))
    if (lsDark) {
      this.setState({ dark: lsDark })
    }
    const lsWizardData = JSON.parse(localStorage.getItem("wizardData"))
    if (lsWizardData) {
      this.setState({ wizardData: lsWizardData })
    }
    const lsProjectRatings = JSON.parse(localStorage.getItem("projectRatings"))
    if (lsProjectRatings) {
      this.setState({ projectRatings: lsProjectRatings })
    }
  }

  render() {
    const { children } = this.props
    const { dark, wizardData, projectRatings } = this.state
    return (
      <UserContext.Provider
        value={{
          dark,
          toggleDark: this.toggleDark,
          wizardData,
          handleWizardChange: this.handleWizardChange,
          projectRatings,
          handleProjectRatingsChange: this.handleProjectRatingsChange,
          resetProjectRatings: this.resetProjectRatings,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider }
