/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/global.css"
import React from "react"
import "firebase/auth"
import "firebase/analytics"
import "firebase/firestore"
import "firebase/functions"
import "firebase/database"
import "firebase/performance"
import { UserProvider } from "./src/context/UserContext"

export const wrapRootElement = ({ element }) => {
  return <UserProvider>{element}</UserProvider>
}
