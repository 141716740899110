import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import UserContext from "../context/UserContext"

const Layout = ({ children }) => {
  return (
    <UserContext.Consumer>{values => <>{children}</>}</UserContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
